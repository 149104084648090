<template>
  <Defaultlayout :userprofile="userprofile">
    <PageSlider></PageSlider>
    <section class="about-us">
      <div class="container">
        <h1 class="page-heading">Return Policy Ghar Bazaar</h1>
        <div class="description">
          <p>We have a no-return & no exchange policy on fresh fruits, vegetables, frozen items, dairy, and other perishable products. But, if you have received an incorrect or damaged product due to the Ghar Bazaar team’s mistake, then a return or exchange can be made contingent that the issue gets reported in a given time frame from the time order gets dropped at the recipient's doorstep, which is within 24 hours for fresh produce and frozen items, and between 24-48 hours for other products. To qualify for a refund a picture proof supporting the issue such as spoilage, damage, incorrect product must be submitted to the Ghar Bazaar team. Failure to submit a proof can lead to disqualification for refund or replacement. Any damages/quality issues reported beyond that period will not qualify for a refund or exchange. Please note that some items you order might be unavailable or have quantity restrictions, in that case, the order will be adjusted to what we have available. Contact support to notify our team via email at <a href="mailto:support@gharbazaar.com">support@gharbazaar.com</a> or by filling out the contact us form available in the app.</p>
        </div>
        <div class="section-heading">
          <p style="margin-bottom: 0;">Who can I contact if I have an issue with my order/refund/replacement/ or placing an order?</p>
            <div class="description">
              <p style="margin-bottom: 0;">After receiving the delivery of an order for any return/ refund/credit/service related concerns email, at <a href="mailto:support@gharbazaar.com">support@gharbazaar.com</a>, & for general inquiries/ to check the order status call:</p>
              <p style="margin-bottom: 0;"> Dallas Metroplex- <a href="tel:(214) 872-1038">(214) 872-1038</a></p>
              <p style="margin-bottom: 0;">Atlanta Metroplex - <a href="tel:(470) 545-0064">(470) 545-0064</a></p>
            </div>
        </div>
      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
  name: "AdvertiseWithUs",
  components: {
    Defaultlayout,
    PageSlider,
  },
  data() {
    return {
      loading: true,
      userprofile: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
};
</script>
<!-- <style>
div.description p:nth-last-child(2),
div.description p:nth-last-child(3) {
  margin-bottom: 0;
}


</style> -->
